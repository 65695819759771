.quiz {

  .boxs {
    box-shadow: 3px solid rgba(255, 84, 33, 0.07) !important;
  }

  .map-canvas {
    overflow: hidden;

    iframe {
      display: inherit;
      width: 100%;
      height: 542px;
      border: unset;
    }
  }

  &.style1 {
    .address-box {
      padding: 50px 40px;
      background: $whiteColor;
      border-radius: 5px;
      text-align: center;

      .address-icon {
        margin-bottom: 18px;

        i {
          color: $orangeColor;

          &:before {
            font-size: 70px;
            line-height: 70px;
          }
        }
      }

      .address-text {
        .label {
          font-size: 22px;
          font-weight: bolder;
          display: block;
          color: $titleColor5;
          font-family: $titleFont;
          margin-bottom: 20px;
        }

        .address {
          color: $titleColor7;
          font-size: 17px;
          font-family: $titleFont;
          font-weight: 600;
        }

        a {
          color: $titleColor7;
          font-size: 17px;
          font-family: $titleFont;
          font-weight: 600;

          &:hover {
            color: $orangeColor;
          }
        }
      }
    }

    .contact-widget {
      .from-control {
        padding: 10px 18px;
        border-radius: 3px;
        border: 1px solid #F1F1F1;
        box-shadow: 0 0 30px #eee;
        color: #767676;
        background-color: $whiteColor;
        width: 100%;
        max-width: 100%;
        outline: none;

        &:active,
        &:focus {
          outline: none;
        }
      }

      textarea {
        height: 140px;
        outline: none;

        &:active,
        &:focus {
          outline: none;
        }
      }


      .btn-send {
        box-shadow: 0px 28px 50px 0px rgba(0, 0, 0, 0.05);
        outline: none;
        border: none;
        padding: 12px 40px;
        border-radius: 3px;
        display: block;
        margin: 50px auto 0;
        font-size: 18px;
        font-weight: 500;
        color: $whiteColor;
        background: $orangeColor;
        transition: all 0.3s ease;
        position: relative;
        overflow: hidden;
        width: 80%;
        text-transform: uppercase;

        &:hover {
          opacity: 0.90;
        }
      }

      .btn-del-cont {
        @extend .btn-send;
        padding: 0px;
        margin: 10px 0;
        border-radius: 50%;
        background-color: $whiteColor;
        font-size: 1rem;
        height: 30px;
        width: 30px;
        color: #4D61F4;

        &:hover {
          background: $secondaryColor;
        }
      }

      .btn-del-modul {
        @extend .btn-del-cont;

        &:hover {
          background: $lightOrangeColor;
        }
      }

      .btn-add {
        border: 2px solid;
        border-color: $secondaryColor;
        background: $secondaryColor;
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        border-radius: 30px;
        line-height: normal;
        font-size: 1em;
        font-weight: 600;
        color: #000;
        transition: all 0.3s ease 0s;
        text-transform: uppercase;
        font-family: navFont;
        margin-bottom: 10px;

        &:hover {
          border-color: $primaryColor;
          background-color: $primaryColor;
          color:#fff;
        }

        .break-words {
          word-break: break-all;
          white-space: pre-line;
        }

        @media (max-width: 767px) {
          .break-words {
            display: block;
          }
        }
      }

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #767676;
        opacity: 1;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        color: #767676;
        opacity: 1;
      }

      :-ms-input-placeholder {
        /* IE 10+ */
        color: #767676;
        opacity: 1;
      }

      :-moz-placeholder {
        /* Firefox 18- */
        color: #767676;
        opacity: 1;
      }
    }
  }

}

.rs-free-contact {
  background: $greenColor;
  padding: 50px 50px 50px 50px;

  .from-control {
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #0C8B51;
    background-color: #127C4B;
    border-radius: 3px;
    padding: 10px 18px;
  }

  textarea {
    height: 140px;
  }

  .submit-btn {
    .btn-send {
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $whiteColor;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $whiteColor;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: $whiteColor;
    opacity: 1;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $whiteColor;
    opacity: 1;
  }
}

@media only screen and (max-width: 1399px) {
  .rs-contact {
    &.style2 {
      .contact-box {
        .box-wraper {
          padding: 40px 20px 60px 30px;

          .address-box {
            .address-icon {
              min-width: 40px;
              margin-right: 15px;
            }
          }
        }
      }
    }

    &.style4 {
      .address-box {
        .address-text {

          .phone,
          .email,
          .address {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      .sec-title {
        .desc {
          &.big {
            font-size: 17px;
            line-height: 27px;
          }
        }
      }
    }
  }
}

@media #{$lg} {
  .gutter-35 {
    margin-left: -15px;
    margin-right: -15px;

    > [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .rs-contact {
    &.style1 {
      .address-box {
        padding: 40px 25px;

        .address-icon {
          i {
            &:before {
              font-size: 50px;
              line-height: 50px;
            }
          }
        }

        .address-text {

          a,
          .desc {
            font-size: 16px;;
          }
        }
      }
    }

    &.style2 {
      .contact-box {
        .box-wraper {
          padding: 40px 20px 50px 30px;

          .address-box {
            display: block;
            text-align: center;

            .address-icon {
              margin-right: 0;
              margin-bottom: 5px;
            }

            .address-text {
              .label {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    &.style3 {
      .sec-title {
        .desc {
          &.big {
            br {
              display: none;
            }
          }
        }
      }

      .map-canvas {
        iframe {
          height: 572px;
        }
      }

      .address-box {
        padding: 45px 30px;

        .address-text {

          .phone,
          .email,
          .address {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }

    &.style4 {
      .sec-title {
        .desc.big {
          font-size: 16px;
          line-height: 26px;

          br {
            display: none;
          }
        }
      }

      .address-box {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        min-height: 265px;

        .address-icon {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 20px;
          width: 70px;
          min-width: unset;
        }
      }

      .contact-widget {
        padding: 60px 40px;
      }

      .map-canvas {
        iframe {
          height: 648px;
        }
      }
    }
  }
}

@media #{$lg_to_sm} {
  .rs-contact {
    &.style1 {
      .address-box {
        min-height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

@media #{$md} {
  .rs-contact {
    .map-canvas {
      iframe {
        height: 450px;
      }
    }

    &.style1 {
      .address-box {
        .address-text {
          .label {
            font-size: 20px;
            margin-bottom: 8px;
          }
        }
      }
    }

    &.style2 {
      .sec-title {
        .desc {
          &.big {
            br {
              display: none;
            }
          }
        }
      }

      .map-canvas {
        iframe {
          height: 450px;
        }
      }

      .contact-widget {
        padding: 70px 80px;
      }
    }

    &.style3 {
      .contact-widget {
        padding: 60px 60px;
      }
    }

    &.style4 {
      .map-canvas {
        iframe {
          height: 450px;
        }
      }

      .address-box {
        padding: 40px 30px;

        .address-text {

          .phone,
          .email,
          .address {
            font-size: 17px;
            line-height: 27px;
          }
        }
      }
    }
  }

  .rs-contact.style2 .contact-box .box-wraper {
    padding-left: 20px;
  }
}

@media #{$sm} {
  .rs-contact {
    &.style1 {
      .sec-title3 {
        .new-desc {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }

    &.style2 {
      .sec-title {
        .desc {
          &.big {
            font-size: 15px;
            line-height: 25px;
          }
        }
      }

      .contact-widget {
        padding: 58px 50px 70px;
      }
    }

    &.style3 {
      .address-box {
        .address-text {

          .phone,
          .email,
          .address {
            font-size: 23px;
            line-height: 33px;
          }
        }
      }
    }

    &.style4 {
      .address-box {
        &:hover {
          margin-top: 0;
        }
      }

      .address-box {
        padding: 40px 30px;
        min-height: unset;

        .address-text {

          .phone,
          .email,
          .address {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

@media #{$xs} {
  .rs-contact {
    .map-canvas {
      iframe {
        height: 300px;
      }
    }

    &.style2,
    &.style3 {
      .map-canvas {
        iframe {
          height: 400px;
        }
      }

      .contact-widget {
        padding: 58px 30px 70px;
      }
    }

    &.style3 {
      .sec-title {
        .desc {
          &.big {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }

      .address-box {
        .address-text {
          .label {
            font-size: 16px;
            margin-bottom: 5px;
          }

          .phone,
          .email,
          .address {
            font-size: 19px;
            line-height: 29px;
          }
        }
      }
    }
  }
}

@media #{$mobile} {
  .rs-contact {
    .map-canvas {
      iframe {
        height: 350px;
      }
    }

    &.style2 {
      .sec-title {
        margin-bottom: 30px;

        .desc {
          &.big {
            display: none;
          }
        }
      }

      .map-canvas {
        iframe {
          height: 400px;
        }
      }

      .contact-widget {
        padding: 45px 15px 60px;
      }
    }

    &.style3 {
      .sec-title {
        .desc {
          &.big {
            display: none;
          }
        }
      }

      .map-canvas {
        iframe {
          height: 400px;
        }
      }

      .address-box {
        display: block;

        .address-icon {
          margin: 0 auto;
          min-width: unset;
          width: 100%;
          max-width: 70px;
        }

        .address-text {
          text-align: center;
          margin-top: 20px;
        }
      }

      .contact-widget {
        padding: 45px 15px 60px;
      }
    }

    &.style4 {
      .sec-title {
        .desc {
          &.big {
            display: none;
          }
        }
      }

      .address-box {
        .address-text {
          .label {
            font-size: 16px;
            margin-bottom: 5px;
          }

          .phone,
          .email,
          .address {
            font-size: 21px;
            line-height: 31px;
          }
        }
      }

      .contact-widget {
        padding: 45px 15px 60px;
      }
    }
  }
}