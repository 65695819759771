/*
Flaticon icon font: Flaticon
Creation date: 31/01/2019 11:48
*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
  url("./Flaticon.woff2") format("woff2"),
  url("./Flaticon.woff") format("woff"),
  url("./Flaticon.ttf") format("truetype"),
  url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-graphic:before {
  content: "\f100";
}

.flaticon-bulb:before {
  content: "\f101";
}

.flaticon-heart:before {
  content: "\f102";
}

.flaticon-money:before {
  content: "\f103";
}

.flaticon-money-1:before {
  content: "\f104";
}

.flaticon-analysis:before {
  content: "\f105";
}

.flaticon-money-2:before {
  content: "\f106";
}

.flaticon-headset:before {
  content: "\f107";
}

.flaticon-law:before {
  content: "\f108";
}

.flaticon-document:before {
  content: "\f109";
}

.flaticon-idea:before {
  content: "\f10a";
}

.flaticon-career:before {
  content: "\f10b";
}

.flaticon-target:before {
  content: "\f10c";
}

.flaticon-speed:before {
  content: "\f10d";
}

.flaticon-admin:before {
  content: "\f10e";
}

.flaticon-tax:before {
  content: "\f10f";
}

.flaticon-diamond:before {
  content: "\f110";
}

.flaticon-partnership:before {
  content: "\f111";
}

.flaticon-growth:before {
  content: "\f112";
}

.flaticon-sales:before {
  content: "\f113";
}

.flaticon-sale:before {
  content: "\f114";
}

.flaticon-whiteboard:before {
  content: "\f115";
}

.flaticon-pen:before {
  content: "\f116";
}

.flaticon-best:before {
  content: "\f117";
}

.flaticon-umbrella:before {
  content: "\f118";
}

.flaticon-dashboard:before {
  content: "\f119";
}

.flaticon-gear:before {
  content: "\f11a";
}

.flaticon-pyramid:before {
  content: "\f11b";
}

.flaticon-business-and-finance:before {
  content: "\f11c";
}

.flaticon-flower:before {
  content: "\f11d";
}

.flaticon-chart:before {
  content: "\f11e";
}

.flaticon-timer:before {
  content: "\f11f";
}

.flaticon-banknote:before {
  content: "\f120";
}

.flaticon-maze:before {
  content: "\f121";
}

.flaticon-suitcase:before {
  content: "\f122";
}

.flaticon-chess-piece:before {
  content: "\f123";
}

.flaticon-puzzle-pieces:before {
  content: "\f124";
}

.flaticon-profits:before {
  content: "\f125";
}

.flaticon-pie-chart:before {
  content: "\f126";
}

.flaticon-computer:before {
  content: "\f127";
}

.flaticon-clock:before {
  content: "\f128";
}

.flaticon-time-call:before {
  content: "\f129";
}

.flaticon-phone:before {
  content: "\f12a";
}

.flaticon-call:before {
  content: "\f12b";
}

.flaticon-email:before {
  content: "\f12c";
}

.flaticon-location:before {
  content: "\f12d";
}

.flaticon-send:before {
  content: "\f12e";
}

.flaticon-bag:before {
  content: "\f12f";
}

.flaticon-supermarket:before {
  content: "\f130";
}

.flaticon-basket:before {
  content: "\f131";
}

.flaticon-shopping-bag:before {
  content: "\f132";
}

.flaticon-right-arrow:before {
  content: "\f133";
}

.flaticon-left-arrow:before {
  content: "\f134";
}

.flaticon-back:before {
  content: "\f135";
}

.flaticon-right-arrow-1:before {
  content: "\f136";
}

.flaticon-next:before {
  content: "\f137";
}

.flaticon-back-1:before {
  content: "\f138";
}

.flaticon-arrow:before {
  content: "\f139";
}

.flaticon-upload:before {
  content: "\f13a";
}

.flaticon-back-2:before {
  content: "\f13b";
}

.flaticon-next-1:before {
  content: "\f13c";
}

.flaticon-ui:before {
  content: "\f13d";
}

.flaticon-back-3:before {
  content: "\f13e";
}

.flaticon-next-2:before {
  content: "\f13f";
}

.flaticon-back-4:before {
  content: "\f140";
}

.flaticon-play-button:before {
  content: "\f141";
}

.flaticon-play-button-1:before {
  content: "\f142";
}

.flaticon-picture:before {
  content: "\f143";
}

.flaticon-image:before {
  content: "\f144";
}

.flaticon-gallery:before {
  content: "\f145";
}

.flaticon-pictures:before {
  content: "\f146";
}

.flaticon-unlink:before {
  content: "\f147";
}

.flaticon-link:before {
  content: "\f148";
}

.flaticon-link-1:before {
  content: "\f149";
}

.flaticon-link-2:before {
  content: "\f14a";
}

.flaticon-speaker:before {
  content: "\f14b";
}

.flaticon-speaker-1:before {
  content: "\f14c";
}

.flaticon-picture-1:before {
  content: "\f14d";
}

.flaticon-picture-2:before {
  content: "\f14e";
}

.flaticon-quotes:before {
  content: "\f14f";
}

.flaticon-right-quote:before {
  content: "\f150";
}

.flaticon-phrase:before {
  content: "\f151";
}

.flaticon-error:before {
  content: "\f152";
}

.flaticon-close-circular-button-of-a-cross:before {
  content: "\f153";
}

.flaticon-add-circular-button:before {
  content: "\f154";
}

.flaticon-cross:before {
  content: "\f155";
}

.flaticon-search:before {
  content: "\f156";
}

.flaticon-shopping-bag-1:before {
  content: "\f157";
}

.flaticon-eye:before {
  content: "\f158";
}

.flaticon-user:before {
  content: "\f159";
}

.flaticon-user-1:before {
  content: "\f15a";
}

.flaticon-clock-1:before {
  content: "\f15b";
}

.flaticon-comment:before {
  content: "\f15c";
}

.flaticon-folder:before {
  content: "\f15d";
}

.flaticon-price-tag:before {
  content: "\f15e";
}

$font-Flaticon-graphic: "\f100";
$font-Flaticon-bulb: "\f101";
$font-Flaticon-heart: "\f102";
$font-Flaticon-money: "\f103";
$font-Flaticon-money-1: "\f104";
$font-Flaticon-analysis: "\f105";
$font-Flaticon-money-2: "\f106";
$font-Flaticon-headset: "\f107";
$font-Flaticon-law: "\f108";
$font-Flaticon-document: "\f109";
$font-Flaticon-idea: "\f10a";
$font-Flaticon-career: "\f10b";
$font-Flaticon-target: "\f10c";
$font-Flaticon-speed: "\f10d";
$font-Flaticon-admin: "\f10e";
$font-Flaticon-tax: "\f10f";
$font-Flaticon-diamond: "\f110";
$font-Flaticon-partnership: "\f111";
$font-Flaticon-growth: "\f112";
$font-Flaticon-sales: "\f113";
$font-Flaticon-sale: "\f114";
$font-Flaticon-whiteboard: "\f115";
$font-Flaticon-pen: "\f116";
$font-Flaticon-best: "\f117";
$font-Flaticon-umbrella: "\f118";
$font-Flaticon-dashboard: "\f119";
$font-Flaticon-gear: "\f11a";
$font-Flaticon-pyramid: "\f11b";
$font-Flaticon-business-and-finance: "\f11c";
$font-Flaticon-flower: "\f11d";
$font-Flaticon-chart: "\f11e";
$font-Flaticon-timer: "\f11f";
$font-Flaticon-banknote: "\f120";
$font-Flaticon-maze: "\f121";
$font-Flaticon-suitcase: "\f122";
$font-Flaticon-chess-piece: "\f123";
$font-Flaticon-puzzle-pieces: "\f124";
$font-Flaticon-profits: "\f125";
$font-Flaticon-pie-chart: "\f126";
$font-Flaticon-computer: "\f127";
$font-Flaticon-clock: "\f128";
$font-Flaticon-time-call: "\f129";
$font-Flaticon-phone: "\f12a";
$font-Flaticon-call: "\f12b";
$font-Flaticon-email: "\f12c";
$font-Flaticon-location: "\f12d";
$font-Flaticon-send: "\f12e";
$font-Flaticon-bag: "\f12f";
$font-Flaticon-supermarket: "\f130";
$font-Flaticon-basket: "\f131";
$font-Flaticon-shopping-bag: "\f132";
$font-Flaticon-right-arrow: "\f133";
$font-Flaticon-left-arrow: "\f134";
$font-Flaticon-back: "\f135";
$font-Flaticon-right-arrow-1: "\f136";
$font-Flaticon-next: "\f137";
$font-Flaticon-back-1: "\f138";
$font-Flaticon-arrow: "\f139";
$font-Flaticon-upload: "\f13a";
$font-Flaticon-back-2: "\f13b";
$font-Flaticon-next-1: "\f13c";
$font-Flaticon-ui: "\f13d";
$font-Flaticon-back-3: "\f13e";
$font-Flaticon-next-2: "\f13f";
$font-Flaticon-back-4: "\f140";
$font-Flaticon-play-button: "\f141";
$font-Flaticon-play-button-1: "\f142";
$font-Flaticon-picture: "\f143";
$font-Flaticon-image: "\f144";
$font-Flaticon-gallery: "\f145";
$font-Flaticon-pictures: "\f146";
$font-Flaticon-unlink: "\f147";
$font-Flaticon-link: "\f148";
$font-Flaticon-link-1: "\f149";
$font-Flaticon-link-2: "\f14a";
$font-Flaticon-speaker: "\f14b";
$font-Flaticon-speaker-1: "\f14c";
$font-Flaticon-picture-1: "\f14d";
$font-Flaticon-picture-2: "\f14e";
$font-Flaticon-quotes: "\f14f";
$font-Flaticon-right-quote: "\f150";
$font-Flaticon-phrase: "\f151";
$font-Flaticon-error: "\f152";
$font-Flaticon-close-circular-button-of-a-cross: "\f153";
$font-Flaticon-add-circular-button: "\f154";
$font-Flaticon-cross: "\f155";
$font-Flaticon-search: "\f156";
$font-Flaticon-shopping-bag-1: "\f157";
$font-Flaticon-eye: "\f158";
$font-Flaticon-user: "\f159";
$font-Flaticon-user-1: "\f15a";
$font-Flaticon-clock-1: "\f15b";
$font-Flaticon-comment: "\f15c";
$font-Flaticon-folder: "\f15d";
$font-Flaticon-price-tag: "\f15e";