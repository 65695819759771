/* CreateCategoryModal.css */
.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modal-content {
    position: relative;
}

.content.loading {
    pointer-events: none;
    opacity: 0.5;
}
