
.backgroundPink {
  background-color: #F8F3FE;
}

.backgroundWhite {
  background-color: #fff;
}

.course-container {

  .pill-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 1em;
    padding: 0;
    background-color: $secondaryColor;
    color:#000000;

    &:hover {
      border-color: $primaryColor;
      background: $primaryColor;
      color: #ffffff;
    }
  }

  .pill-position {
    right: 25%;
  }

  .btn-course {
    border: 2px solid;
    border-color: $secondaryColor;
    background: $secondaryColor;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 30px;
    line-height: normal;
    font-size: 1em;
    font-weight: 600;
    color: #000000;
    transition: all .3s ease 0s;
    text-transform: uppercase;
    font-family: navFont;
    margin-bottom: 10px;

    &:hover {
      border-color: $primaryColor;
      background: $primaryColor;
      color: #ffffff;
    }
  }

  .completed-icon {
    background-color: #a4f3b6;
    color: #0cae32;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 20px;
    padding: 0;
    margin-right: 7px;
  }

  .content-box {
    .text-content p {
      line-height: 1.4em;
    }

    .option {
      cursor: pointer;
      padding: 5px;
      margin-bottom: 5px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .option:hover {
      background-color: #fcda7d;
    }

    .selected {
      background-color: #86c0fe;
    }

    .correct {
      background-color: #a4f3b6 !important;
    }

    .incorrect {
      background-color: #f99aa2 !important;
    }
  }

  .content-menu {
    .button-list {
      background-color: $secondaryColor;
      border-radius: 3px;
      color: $titleColor7;
      font-size: 1em;
      font-family: $titleFont;
      font-weight: 600;
      padding: 10px 10px;
    }

    .button-menu {
      border-radius: 3px;
      color: $titleColor7;
      font-size: 1em;
      font-family: $titleFont;
      font-weight: 600;
    }
  }
}

.courses-item {
  position: relative;

  .img-part {
      position: relative;

      img {
          display: block;
          width: 100%;
          height: auto;
      }

      // Estilos para el tag "Finalizado"
      .completed-tag {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: $primaryColor;
          color: white;
          padding: 5px 10px;
          border-radius: 5px;
      }

      // Estilos para el tag "En curso"
      .in-progress-tag {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: #ABC4FF;
          color: #111111;
          padding: 5px 10px;
          border-radius: 5px;
      }

      // Estilos para el tag "Fav"
      .favorite-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #ffffff;
        padding: 5px 10px;
        font-size: 25px;
    }
  }

  
}

