/* button style */
.btn {
    border: 2px solid;
    border-color: $secondaryColor;
    background: $secondaryColor;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 30px;
    line-height: normal;
    font-size: 1em;
    font-weight: 600;
    color: #000;
    transition: all 0.3s ease 0s;
    text-transform: uppercase;
    font-family: navFont;
    margin-bottom: 10px;

    &:hover {
      border-color: $primaryColor;
      background: $primaryColor;
      color: #fff;
    }
}

.btn-text {
  color: $white;
  position: relative;
  transition: .3s;
}

.btn:hover .btn-text {
  color: $white;
}

.btn-text i {
  padding-left: 5px;
}

.btn-white {
  background: $white;
}

.btn-white .btn-text {
  color: #333333
}

.btn:hover .btn-text {
  color: $white;
}

.breadcrumb > .active {
  color: #888;
}

.pink-btn {
  background: #d10459;
}

.pink-btn .btn-text {
  color: #fff;
}

.pink-btn .btn-border {
  background: #fff;
}

.btn-white:hover::before {
  background: #fff;
}

.btn-white .btn-text:hover {
  color: $black;
}

.btn-none::before {
  display: none;
}

.border-btn {
  -moz-user-select: none;
  border: 2px solid $white;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  position: relative;
  font-family: $c_bold;
}

.btn-text {
  color: $white;
  position: relative;
  transition: .3s;
}

.border-btn:hover {
  border-color: $theme-color;
}

.btn-text i {
  padding-left: 5px;
}

.border-btn::before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: $theme-color;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
  border-radius: 5px;
}

.border-btn:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  background: #1876df;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete-button-file {
  margin-left: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
