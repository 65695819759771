// pagination
.basic-pagination {
  & ul {
    display: block;

    & li {
      display: inline-block;
      @media #{$xs}{
        margin-bottom: 10px;
      }

      & a {
        height: 70px;
        width: 70px;
        background: $white;
        color: #9990b8;
        font-size: 16px;
        font-weight: 500;
        border-radius: 50%;
        line-height: 70px;
        margin: 0 5px;
        display: inline-block;
        text-align: center;

        &:hover {
          background: $theme-color;
          color: $white;
        }
      }
    }
  }
}

.basic-pagination-2 {
  & ul {
    & li {
      & a {
        background: transparent;
        color: #9990b8;
        border: 2px solid #eaebec;
        height: 60px;
        width: 60px;
        line-height: 56px;

        &:hover {
          background: $theme-color;
          color: $white;
          border-color: $theme-color;
        }
      }

      &.active {
        & a {
          background: $theme-color;
          color: $white;
          border-color: $theme-color;
        }
      }
    }
  }
}


.pagination-part {
  display: inline-flex;
  align-items: center;
  border: 1px solid #f9f9f9;
  padding: 11px 0;
  border-radius: 3px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .05);
  background: #fff;

  li {
    padding: 2px 20px 0 21px;
    line-height: 23px;
    font-size: 18px;
    text-decoration: none;
    transition: all .4s ease-in-out 0s;
    text-align: center;
    background: 0 0;
    color: #000;
    display: inline-block;

    a {
      color: $bodyColor;

      &:hover {
        color: $primaryColor;
      }
    }

    &:last-child {
      border-right: unset;
    }
  }

  .next-page {
    position: relative;
    transition: all .4s ease-in-out 0s;
    padding-right: 12px;

    &:before, &:after {
      content: "\f105";
      position: absolute;
      top: 6px;
      right: 0;
      line-height: 16px;
      font-family: "FontAwesome";
      font-size: 12px;
    }

    &:before {
      right: 4px;
    }
  }

  .active {
    a {
      color: $primaryColor;
    }
  }
}

.pagenav-link {
  ul {
    border: 1px solid #f2f2f2;
    border-radius: 3px;
    display: inline-block;
    padding: 0 3px;

    li {
      display: inline-block;
      padding: 0 18px;
      margin: 14px 0;
      border-right: 1px solid #E6E6E6;
      color: $primaryColor;
      font-size: 16px;

      a {
        color: $bodyColor;

        i {
          &:before {
            color: $bodyColor;
            font-size: 13px;
            transition: $transition;
          }
        }

        &:hover {
          color: $primaryColor;

          i {
            &:before {
              color: $primaryColor;
            }
          }
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  &.orange-color {
    ul li,
    ul li a:hover,
    ul li a:hover i:before {
      color: $orangeColor;
    }
  }
}