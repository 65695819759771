.ioqKhC {
    font-weight: 600 !important;
}

.lnOUep {
    font-weight: 600 !important;
}


@media #{$laptop} {
    .iaRYAN {
        padding: 15px !important;
    }

    .dERlwn {
        padding: 0.8em !important;
    }

    .bKIGtx {
        height: 40px;
        min-height: 40px !important;
        font-size: 0.8em !important;
    }

    .eXWrwD {
        font-size: 1em !important;
    }

    .ioqKhC {
        height: 40px;
        min-height: 40px !important;
        border-bottom-color: grey !important;
        background-color: #f7f6f6 !important;
    }

    .table-container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .btn2, .btn-status {
        font-size: 13px;
        display: inline-block;
        padding: 8px 10px;
    }

    .bqDyAs {
        background-color: #f7f6f6 !important;
    }

    .dpVRlR h6 {
        font-size: 14px;
    }

    .dpVRlR ul li {
        font-size: 13px;
    }
    
}
  

@media #{$lg} {
    .iaRYAN {
        padding: 5px !important;
    }

    .dERlwn {
        padding: 5px !important;
    }

    .bKIGtx {
        height: 40px;
        min-height: 40px !important;
    }

    .ioqKhC {
        height: 40px;
        min-height: 40px !important;
        border-bottom-color: grey !important;
        background-color: #f7f6f6 !important;
    }

    .table-container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .btn2, .btn-status {
        font-size: 13px;
        display: inline-block;
        padding: 8px 10px;
        border-radius: 5px;
    }

    .bqDyAs {
        background-color: #f7f6f6 !important;
    }

    .dpVRlR h6 {
        font-size: 14px;
    }

    .dpVRlR ul li {
        font-size: 13px;
    }
    
}
  
@media #{$md} {
    
}
  
@media #{$sm} {
    
}
  
@media #{$xs} {
    
}
  
@media #{$mobile} {
    .iaRYAN {
        padding: 5px !important;
    }

    .dERlwn {
        padding: 5px !important;
    }

    .rdt_TableCol {
        font-size: 12px !important;
    }

    .rdt_TableCell {
        font-size: 12px !important;
    }

    .bKIGtx {
        height: 35px;
        min-height: 35px !important;
    }

    .ioqKhC {
        height: 35px;
        min-height: 35px !important;
        border-bottom-color: grey !important;
        background-color: #f7f6f6 !important;
    }

    .bbbqun {
        padding-right: 5px !important;
        padding-left: 5px !important;
        color: $primaryColor !important;
        font-size: 12px !important;
        height: 40px !important;
        min-height: 40px !important;
    }

    .table-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .btn2, .btn-status {
        font-size: 12px;
        display: inline-block;
        padding: 6px 8px;
        border-radius: 5px;
    }

    .bqDyAs {
        background-color: #f7f6f6 !important;
    }

    .dpVRlR h6 {
        font-size: 13px;
    }

    .dpVRlR ul li {
        font-size: 12px;
    }
}