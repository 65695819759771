.btn2 {
  font-size: 20px;
  background: $primaryColor;
  color: #fff;
  display: inline-block;
  padding: 6px 22px;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease, color 0.75s ease;

  &.btn-modify {
    &:hover {
      background-color: black;
      color: white;
    }
  }

  &.btn-delete {
    &:hover {
      background-color: $secondaryColor;
      color: #fff;
    }
  }

  &:hover {
    transform: translateY(-2px);
    transition: transform 0.75s ease;
  }
}

body.swal2-shown {
  padding-right: 0 !important;
}

.btn-status {
  @extend .btn2;
  background-color: #e4e4e4;

  .toggle-off {
    color: red;
  }

  .toggle-on {
    color: green;
  }

  &.toggle-on:hover {
    color: black;
    transform: translateY(-2px);
    transition: transform 0.75s ease;
  }
}

.btn-status:hover {
  background-color: black;
  transform: translateY(-2px);
  transition: transform 0.75s ease;
}


.table-container {
  max-width: 100%;
}

.rdt_TableCol {
  font-size: 16px !important;
}

.rdt_TableCell {
  font-size: 16px !important;
}

.jUVdIZ {
  justify-content: center !important;
}